<template>
	<div class="to_top" :style="{bottom:show?'80px':'-1000px'}" @click="toTop"></div>
</template>

<script>
import { throttle } from "@/utils/utils";
export default {
  data() {
    return {
      show: false
    };
  },
  mounted() {
    document.addEventListener("scroll", throttle(this.scrollHandle, 50));
  },
  methods: {
    scrollHandle(e) {
      this.show = document.documentElement.scrollTop > 300;
    },
    toTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    }
  }
};
</script>

<style lang="scss">
.to_top {
  width: 60px;
  height: 60px;
  background: url("../assets/images/news/to_top.png") no-repeat;
  background-size: 100%;
  position: fixed;
  bottom: 80px;
  right: 10%;
  transition: all 0.3s;
  cursor: pointer;
}
</style>
