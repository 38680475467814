<template>
	<div class="newsDetails">
		<div class="details_main" v-if="id == 2">
			<div class="details_icon" @click="newsList"></div>
			<h2>“翰林院音乐录音棚”开棚仪式在京举行</h2>
			<p>发布时间：2017-10-15<span>浏览量：10w+</span></p>
			<div class="details_cont">
				<p>10月15日，由北京翰林苑国际文化传播有限公司(下称“翰林苑”) 匠心打造的翰林院音乐录音棚在北京举行开棚仪式。中国文联原副主席徐沛东、中央电视台总录音师李小沛、著名作曲家印青以及英国ams neve公司客户经理James Townend等20多位国内外知名音乐人、顶级录音师莅临开棚仪式，并为翰林院音乐录音棚首次录音试音。</p>
				<img src="@/assets/images/news/3_1.jpg" />
				<p>(翰林苑董事长林虹在开棚仪式致辞中表示，国内各地兴建的大量电影产业园和制作基地，其整体制作水准较欧美仍有差距，原因之一就是电影制作产业链严重缺失，尤其是在声音制作方面，进入门槛高、专业人才短缺，这一现状已经成为国产电影制作的一大短板。)</p>
				<p>“翰林院音乐录音棚旨在缩短、甚至是弥补这一短板，这里是一处集声乐录音、民族及古典乐队录音、流行音乐录音与制作、电影音乐混音于一体的国际水准高品质一站式音乐制作中心。声学设计指标严格按照国家一级标准执行，声学材料采用法国进口环保产品，录音及编辑系统均采用国际顶级厂家产品，包括目前国内唯一一台 48通道 Genesys Black调音台。为了更好的为音乐人服务，除了配备顶级的设备，翰林苑在引进高级录音师人才时也严把素质关和技术关，旨在为音乐、为影视、为艺术做出最好的作品提供优质平台 。” 翰林苑林虹说。</p>
				<img src="@/assets/images/news/3_2.jpg" />
				<span>(图为英国ams neve公司客户经理James Townend先生在为设备进行调试。)</span>
				<p> 据环球网了解，北京翰林院音乐录音棚，座落于北京香山脚下绿地公园内,由录音室、5.1控制室、音频编辑室等三个专业棚(室)组成，是一处集声乐录音、民族及古典乐队录音、流行音乐录音与制作、电影音乐混音于一体的国际水准高品质一站式音乐制作中心。</p>
				<img src="@/assets/images/news/3_9.jpg" />
				<span>(图为中国文联原副主席徐沛东接受环球网财经专访。)</span>
				<p>在开棚仪式上，中国文联原副主席徐沛东在翰林院音乐录音棚进行录音体验，并在试音之后接受环球网财经专访。徐沛东表示,中国的录音技术已迈入数字化时代的进程。翰林院音乐录音棚配备国内顶级设备，助力我国录音技术迈上新台阶，在数字化时代和模拟技术的转换之间形成了行业风向标。“在刚才的试音过程中，我最大的感受是，音乐本真的东西可以通过模拟录音台，模拟数字的转换实现完美的展现。现在录音技术发展很快，我相信翰林院音乐录音棚能在塑造民族文化品牌上助一把力。”徐沛东说。</p>
				<img src="@/assets/images/news/3_3.jpg" />
				<span>(图为录音大师李小沛接受环球网财经专访。)</span>
				<p>中国中央电视台总录音师、国家一级录音师李小沛在接受环球网财经专访时表示，翰林苑能在国内音乐市场低迷的情况下，不计成本的为音乐人打造高端录音棚，值得称赞。“近几年，针对哪种录音技术更好的问题，业内一直围绕是采用模拟录音好还是数字录音好而进行讨论，且逐步兴起模拟回归潮流，而翰林院音乐录音棚是融合模拟和数字技术为一体，既有现代的东西，又保留了一种人文的味道。”李小沛说。</p>
				<img src="@/assets/images/news/3_4.jpg" />
				<span> (图为著名作曲家印青接受环球网财经专访。)</span>
				<p>拥有丰富录音经验的著名作曲家印青，在参观完翰林院音乐录音棚之后感言：“翰林院音乐录音棚除了配备国际顶尖设备保障录音效果，还为音乐人提供了非常舒适的艺术创造环境。翰林院音乐录音棚坐落在香山脚下，绿地公园内，环境优美、场所僻静，可以激发艺术家的创作灵感，尽情地发挥其音乐艺术的创造性。”</p>
				<img src="@/assets/images/news/3_5.jpg" />
				<span> (图为著名录音师陈珞接受环球网财经专访。)</span>
				<p>享有“中国五大录音师之一”之誉的著名录音师陈珞从国内录音行业发展和使用者的角度出发，对翰林院音乐录音棚给予厚望。他说：“在过去，我们中国有许多优秀的音乐作品因为收录问题而无法完整保存下来，在今天，翰林院音乐录音棚凭借顶级的设备和先进的技术将会帮助音乐人更好的记录下音乐作品的创作过程，我相信，我们的音乐人、艺术家将在这里大展拳脚，我也期待在翰林院音乐录音棚能早日创作出更好的音乐作品。”</p>
				<img src="@/assets/images/news/3_6.jpg" />
				<span> (图为武警国安剧院录音棚总录音师陈庆接受环球网财经专访。)</span>
				<p>武警国安剧院录音棚总录音师陈庆从声学设计的角度点评了翰林院音乐录音棚。陈庆指出，创作一部好的作品，需要一流的设备配合完善的集成系统。“翰林院音乐录音棚的声学设计方案送到英国的杜比实验室，通过了录音棚的声学设计审核，从录音室、控制室到音频后期编辑室，彼此之间互相配合联动，非常协调。”陈庆说。</p>
				<img src="@/assets/images/news/3_7.jpg" />
				<span>(图为中国传媒大学教授，国家一级录音师，中国录音师协会理事李大康接受环球网财经专访。)</span>
				<p>中国传媒大学教授，国家一级录音师，中国录音师协会理事李大康认为，一流的录音棚，价值核心在“好用”。李大康说：“翰林院音乐录音棚有两点让人‘耳目一新’的地方。第一点，设备引进新概念，进一步实现与国际接轨。录音及编辑系统均采用国际顶级厂家产品，包括国内唯一一台48通道Genesys Black 调音台。第二点，录音棚设计理念人性化，除了工作区域，翰林苑还建造了休闲区，方便艺术家在工作之余，放松身心，打造更优秀的音乐作品。”</p>
				<img src="@/assets/images/news/3_8.jpg" />

			</div>
		</div>
		<div class="details_main" v-if="id == 1">
			<div class="details_icon" @click="newsList"></div>
			<h2>“五月鲜花五支歌”新歌发布会</h2>
			<p>发布时间：2019-11-07<span>浏览量：10w+</span></p>
			<div class="details_cont">
				<p>5月23日，为纪念延安文艺座谈会召开74周年，以实际行动落实习近平总书记在文艺工作座谈会上的重要讲话精神，中国长城学会、北京翰林苑国际文化传播有限公司联合主办了《五月鲜花五支歌》新歌发布会。</p>
				<img src="@/assets/images/news/1_1.jpg" />
				<p>参加新歌发布会的有中国长城学会常务副会长、解放军原总后勤部副部长周友良中将、中国文联原副主席、海军原副政委王兆海中将、中国文联党组副书记甘英烈、中国文联原副主席仲呈祥等领导，以及艺术界和媒体记者。</p>
				<p>《刻骨铭心》(晓光作词)、《中华魂》(晓光作词)、《琴棋书画》(虹姐作词，刘青作曲)、《女儿心》(贺东久作词，印青作曲)、《爸爸我想你》(贺东久作词，舒楠作曲)五首歌曲，以充沛的激情，生动的笔触，优美的旋律，形象生动地体现了社会主义核心价值观和中华优秀传统文化精神，抒发了民族自豪感和爱国主义情怀，深具文化的影响力、艺术的感染力、情感的打动力和思想的正能量。为鲜花盛开的火红五月增添了一抹艳丽的亮色。</p>
				<p>　　词曲作者既有声誉卓著的名家大师，也有才华横溢的后起之秀。《刻骨铭心》、《中华魂》是表现民族精神和爱国情怀的“姊妹篇”，其中《刻骨铭心》是大型历史文献纪录电影《那岁月
					刻骨铭心》的主题歌。创作者怀着对历史的敬畏之心追忆抗战烽火，探究国家命运，寻找到不同一般的情感切入点，虽时光荏苒，时过境迁，但那段有着切肤之痛的民族伤痕永远无法抚平，
					发出“国殇凝聚起血性之躯”，“烽火锻造出民族精神”的感慨与呼唤。《中华魂》以讴歌中华民族威武不屈、前赴后继、一往无前的伟大民族精神为主题，运用将政治内涵熔铸于艺术语言和动听旋律之中的手法，传扬凝重深刻的思想和情感。这两首歌曾参加了由中国长城学会主办的大型抗战史诗《筑成我们新的长城》公益演出，受到一致好评。</p>
				<p>《女儿心》和《爸爸我想你》词曲回环复沓、一咏三叹，意境唯美抒情，歌词质朴动人，曲真情切，把孝道理念和价值观念融汇在艺术语言、创作情感中，以巧妙的艺术性和强烈的感染力展现中国孝道文化的精神内涵。传递出华夏儿女讲仁爱，重亲情、讲友善、孝老爱亲的人伦道德精神。</p>
				<p>《琴棋书画》极具东方文化特色。词与曲富有层次、余韵无穷，犹如一幅笔端蕴秀临窗写就的素雅心笺，仿佛微风中静静流淌石上的山泉溪涧，清泠透亮而又蜿蜒萦绕。娓娓道出琴棋书画看似平常的艺术中蕴含的情、趣、境、气、韵、味、品等中华美学精神，折射出我国深远文化的光彩</p>
				<p>参加发布会的领导、嘉宾和新闻媒体的记者们，现场聆听了著名歌唱家王传越、吕薇，著名青年歌手周澎、添添、刘坤山演唱的五首歌曲，切身感受到创作者出于真情、来自实感、发自心底的情感冲动和向往追求，同时也感受到了优秀音乐作品所起到的春风化雨，润物无声，启迪思想，温润心灵，陶冶情操的作用，释放出满满的正能量。</p>
				<img src="@/assets/images/news/1_2.jpg" />
				<p>音乐是人类的通用语言。享誉世界的中国小提琴演奏家王晓明，长期与北京翰林苑国际文化传播有限公司有着十分密切的合作，此次他亲自率领由韩国小提琴演奏家尹昭泳、意大利中提琴演奏家莱希·安东尼奥·乌斯琼斯基和瑞士大提琴演奏家玛雅·韦伯组成的皇家“斯特拉迪瓦里四重奏”到场祝贺五首新歌发布成功。</p>
				<p>亲身参加文艺工作座谈会的老一辈艺术家田华老师，深情讲述当年在延安文艺座谈会讲话精神指引和激励下，塑造喜儿、王玉梅等人物形象的过程，畅谈学习习总书记关于文艺工作重要讲话的体会，并朗诵了《沁园春·雪》，表达了对新一代文化艺术工作者寄予“数风流人物还看今朝”的厚望。</p>
				<p>北京翰林苑国际文化传播有限公司董事长林述志在发言中说，“翰林苑”坚持以发展大众文化产业为已任，致力于原创文化精品的普及化、大众化，始终用高雅艺术弘扬中华优秀传统文化，用优秀作品高扬新时代中国精神。</p>
				<p>中国长城学会常务副会长周友良中将对这五首新歌给予了高度评价，称赞是思想性和艺术性完美结合的统一，相信能够广为流传，发挥很好的社会效益。</p>
			</div>
		</div>
		<div class="details_main" v-if="id == 3">
			<div class="details_icon" @click="newsList"></div>
			<h2>文献纪录片《那岁月刻骨铭心》赠送仪式暨翰林苑爱国教育基地开馆活动在京举行</h2>
			<p>发布时间：2019-10-18<span>浏览量：10w+</span></p>
			<div class="details_cont">
				<p>7月11日，为纪念全民族抗战爆发80周年，由环球网、北京翰林苑国际文化传播有限公司联合主办的“文献纪录片、大型画册《那岁月刻骨铭心》赠送仪式暨翰林苑爱国教育基地开馆”活动在北京举行。本次活动以“勿忘国耻警钟长鸣”为主题，旨在铭记历史、缅怀先烈、珍爱和平、开创未来，大力弘扬伟大抗战及爱国主义精神，让爱国主义精神在广大青少年心中牢牢扎根，培养青少年爱国之情、砥砺强国之志、实践报国之行，让爱国主义精神代代相传、发扬光大。</p>
				<img src="@/assets/images/news/2_1.jpg" />
				<span>(图片说明:7月11日，为纪念全民族抗战爆发80周年，由环球网、北京翰林苑国际文化传播有限公司联合主办的“文献纪录片、大型画册《那岁月刻骨铭心》赠送仪式暨翰林苑爱国教育基地开馆”活动在北京举行。)</span>
				<h2>抗战老兵：“那岁月，刻骨铭心”</h2>
				<p>80年前的中华民族受尽屈辱，帝国主义列强在我国土上横行，特别是日本帝国主义在中国的烧杀抢掠，罪孽深重。文献纪录片《那岁月刻骨铭心》从公元663年中日历史第一次交战开始，至1945年日本战败投降结束，展示了1000多本、报纸115份珍贵文献证据资料，以及日本绘制的华夏地图100多幅、日本随军记者所拍摄照片的600多张，全面梳理了中日两国千余年的战争史，深入浅出阐述了有关日本侵华的种种罪行。</p>
				<p>5位曾经参加抗日战争的老兵专程为活动录制了VCR，他们平均年龄91岁，最年长的刘立生老人今年95岁，曾经参加过百团大战。虽已年迈，但老兵风骨依旧，一声声“那岁月，刻骨铭心”铿锵有力、掷地有声，那是他们对日本侵略者暴行的愤慨，更是他们对于祖国解放事业的赤胆忠心，他们是“活着的历史”，是中华民族抗日战争宝贵的精神财富。</p>
				<h2>林虹：“让历史说话，用史实发言”</h2>
				<p>北京翰林苑国际文化传播有限公司董事长、《那岁月刻骨铭心》纪录片出品人林虹是在海外留学归国的年轻女士，谈起80年前那段历史，林虹说，“我的爸爸是一位商人，但他还曾经是一名战士，是一名在和平年代亲历过前线战火和牺牲的真正的战士，可以说，战士的精神基因融入了他的血脉。所以，在老兵爸爸的影响下，无论脚踏祖国的大好河山还是求学于遥远的异国他乡，我的骨子里的那份家国情怀一直铭记于心。”林虹在致辞中说，“作为公司负责人和文献纪录片《那岁月刻骨铭心》的出品人，我认为，一家成功的企业不应该只是追求物质生活的丰富，更要有精神的持守。我愿意将翰林苑爱国主义教育基地当作一个阵地，我愿意作为排头兵，传承这种燃烧的激情和不灭的意志!”</p>
				<img src="@/assets/images/news/2_2.jpg" />
				<span>(图片说明:北京翰林苑国际文化传播有限公司董事长、《那岁月刻骨铭心》纪录片出品人林虹现场致辞。)</span>
				<p>环球网总经理单成彪为活动送上祝语，并表达了对《那岁月刻骨铭心》主创团队的诚挚敬意。单成彪说：“用历史说话才能走向未来，坚持正确的历史观，直接关系国家前途命运和人类未来，遗忘和掩盖甚至歪曲历史最终只会陷入悲剧的泥潭。环球网将不断提高媒体话语能力，为传播正确的历史观、争取国际话语主动权夯实基础。”</p>
				<img src="@/assets/images/news/2_3.jpg" />
				<span>(图片说明: 环球网总经理单成彪致辞。)</span>
				<h2>专家学者：纪念抗战是现实意义与教育意义并行</h2>
				<p>在专家论坛环节，侵华日军南京大屠杀遇难同胞纪念馆原馆长朱成山，中国军事科学学会战略分会会长、《那岁月刻骨铭心》纪录片军事顾问姚有志，北京大学教授、东北亚研究所所长、中国日本史学会副会长宋成有，中国军事科学院研究员、《那岁月刻骨铭心》纪录片总撰稿魏碧海，从各自角度阐述了自己对影片的看法、并评价其爱国主义教育意义重大。</p>
				<img src="@/assets/images/news/2_4.jpg" />
				<span>(图片说明:专家论坛环节，五位嘉宾高度评价影片艺术价值。侵华日军南京大屠杀遇难同胞纪念馆原馆长朱成山说，“在80周年这个有意义的年份出一部反映那一段刻骨铭心历史的纪录片，出这样一本画册非常有价值和意义。我从南京赶来，我觉得对民众、特别对青少年教育这是一个非常好的教材。作为博物馆的工作者参观了这样一个展览，有那么多第一手的报刊、杂志、资料，民间能有这样一个收藏，很了不起，非常钦佩。”)</span>
				<p>中国文联原副主席、书记处书记，著名文艺评论家仲呈祥，从影视高度总结评价影片价值及意义，认为这是“国人必看的一部纪录片”。仲呈祥在致辞时说，“我今天参加这个活动深受感动，我认为这是一件功在当代、利在千秋的大好事，对于我们当下中国文化的健康发展投下了一颗有声有色的石子，必然引起民族的反思和重视。”</p>
				<img src="@/assets/images/news/2_5.jpg" />
				<span>(图片说明:中国文联原副主席、书记处书记，著名文艺评论家仲呈祥现场致辞。)</span>
				<p>中国社会科学院世界史所研究员、中国日本史学会名誉会长、《那岁月刻骨铭心》纪录片历史总顾问汤重南，从历史高度总结评价影片价值及意义。汤重南表示：“我相信这样一系列的活动可以永远的开展下去，使越来越多中国的民众能够看到这样的纪录片、专业的画册，提高我们爱国主义情怀，真正还原历史，总结经验教训，使这样悲惨的历史不再重演。”</p>
				<img src="@/assets/images/news/2_6.jpg" />
				<span>(图片说明:中国社会科学院世界史所研究员、中国日本史学会名誉会长、《那岁月刻骨铭心》纪录片历史总顾问汤重南致辞。)</span>
				<p>中国文联原副主席、《那岁月刻骨铭心》总策划王兆海，从政治高度总结评价影片价值及意义。王兆海表示：“在这里我想对三件事点赞。第一就是为这部纪录片点赞。这部片子跟以往我们看到的百年抗战题材有很多的不同，有很多新的东西。翰林苑团队从日本花了很多钱购买回来一大批当年日本在中国真实的影像记录和大量军事资料、地图、笔记。翰林苑团队前后用了五六年时间，著名的军事专家、一大批顶级的抗战史日本史研究的专家倾注心血，这是一部很好的爱国主义教材。在这个时候推出这部片子的目的就是铭记历史、缅怀先烈、珍视和平、警示未来。第二要为翰林苑爱国教育基地点赞。翰林苑在制作这部纪录片的时候，从国内到国外收集了很多珍贵的资料，所以在完成这部纪录片制作的同时，把这些资料、图片陈列起来办成一个爱国教育基地，将来学生到这里来不仅可以观看这部珍贵的历史文献纪录片，同时还可以看到当年珍贵的图片、资料、文墨，为海淀区、为北京市增加了一个爱国教育点。第三我要为翰林苑的林总父女点赞。一个民营企业家自己掏腰包，拿出来一千多万元制作一部历史文献纪录片，对抗日文化的研究，对中国传统文化的弘扬投入很大。我衷心祝愿翰林苑这朵灿烂的文化之花能够结出更多的丰硕之果。”</p>
				<img src="@/assets/images/news/2_7.jpg" />
				<span>(图片说明:中国文联原副主席、《那岁月刻骨铭心》总策划王兆海致辞。)</span>
				<h2>民间爱国力量是对历史最好的铭记</h2>
				<p>《那岁月刻骨铭心》出品方分别向到场的中国人民革命军事博物馆副馆长马琳杰、中国人民抗日战争纪念馆副馆长罗存康、侵华日军南京大屠杀遇难同胞纪念馆原馆长朱成山、中国传媒博物馆馆长潘力，军队代表和高校代表赠送了文献纪录片、大型画册《那岁月刻骨铭心》。本次捐赠，亦有利于后续在广大青少年中开展深入、持久、生动的爱国主义宣传教育，让爱国主义精神代代相传、发扬光大。</p>
				<img src="@/assets/images/news/2_8.jpg" />
				<span>(图片说明:《那岁月刻骨铭心》出品方向中国人民革命军事博物馆、中国人民抗日战争纪念馆、侵华日军南京大屠杀遇难同胞纪念馆、中国传媒博物馆代表赠送了文献纪录片。)</span>
				<p>最后，各领导、嘉宾，郁钧剑、黄宏、程桂兰、吕薇等文艺界代表及媒体参观了翰林苑爱国教育基地。在蜿蜒几百米的长廊中，展出了《那岁月刻骨铭心》收集的全部史料，包括日本新闻机构在战时出版的画报1000多本、报纸115份，日本绘制的华夏地图100多幅，日本随军记者所拍摄照片的600多张，以及从美国、日本，包括南京大屠杀、日军轰炸中国各城市所购买来的大量珍贵影像资料。这个展览是纯粹的民间行为，是民间爱国力量及爱国智慧的展示，是对历史最好的铭记。铭记历史，是为了珍爱和平，更是为开创未来，汲取历史经验教训，使时代潮流汹涌向前。</p>
			</div>
		</div>
		<GoTop />
	</div>
</template>
<script>
import GoTop from "@/components/GoTop.vue";
export default {
  name: "newsDetails",
  components: {
    GoTop
  },
  data() {
    return {
      id: 0
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
  methods: {
	  newsList(){
		   this.$router.push({ path:'/news'})
	  }
  }
};
</script>
<style lang="scss" scoped>
.newsDetails {
  background: #061319;
  position: relative;
  .details_main {
    width: 900px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .details_icon {
      width: 166px;
      height: 44px;
      background: url(../assets/images/news/news.png) no-repeat;
      background-size: 100%;
	  margin: 213px auto 45px;
	  cursor: pointer;
    }
    > h2 {
      font-size: 32px;
      font-family: 'OpenSansRegular';
    }
    > p {
      font-size: 16px;
	  line-height: 32px;
	  font-family: 'OpenSansRegular';
      > span {
        padding-left: 80px;
      }
    }
    .details_cont {
      width: 100%;
      height: 100%;
      margin: 40px 0 200px;
      > p {
        text-indent: 2em;
        text-align: left;
        font-size: 18px;
		line-height: 30px;
		margin-bottom: 24px;
		font-family: 'OpenSansLight'; 	
      }
      > img {
        margin: 40px 0px 20px;
        width: 600px;
        height: 368px;
      }
      > span {
        display: block;
        font-size: 17px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>